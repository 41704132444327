import {ReactNode, useCallback, useContext, useEffect, useState} from "react";

import {AuthErrorBoundaryContext} from "./UnauthorizedErrorBoundary";
import {ErrorReason} from "../../../../app/client/types";
import {UnknownErrorBlock} from "./UnknownError";


interface Props {
    children: ReactNode | ReactNode[]
    errorMatcher: (reason: ErrorReason) => boolean
    dependencyHash: string
}

const ChainErrorBoundary = (props: Props) => {
    const {submitError} = useContext(AuthErrorBoundaryContext);
    const [hasError, setHasError] = useState(false);
    const submitErrorCallback = useCallback((reason: ErrorReason) => {
        if (props.errorMatcher(reason)) {
            setHasError(true);
        } else {
            submitError(reason)
        }
    }, [props.errorMatcher, submitError, setHasError]);
    useEffect(() => {
        setHasError(false);
    }, [props.dependencyHash, setHasError])

    return <AuthErrorBoundaryContext.Provider value={{
        reason: ErrorReason.NONE,
        submitError: submitErrorCallback,
    }}>
        { hasError
            ? <UnknownErrorBlock/>
            : props.children
        }
    </AuthErrorBoundaryContext.Provider>
}

export {ChainErrorBoundary}