import React, {useCallback} from "react";
import {AnyAction} from "redux";

import {Stack} from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';

import {openTableContextMenu} from "./data/action";
import {Rp3CellState, Rp3TableState} from "./data/state";
import {WorkspaceBlock, WorkspaceBlockHeader} from "../../../../qdep/components/app/workspace/Workspace";
import {Label} from "../../../../qdep/components/app/util/label/Label";
import {TableTitleContainer} from "../base/report-table-title-container/TableTitleContainer";
import {ReportTableWorkspaceBlockBody} from "../base/report-table/ReportTableWorkspaceBlockBody";
import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";


interface ReportTableProps {
    table: Rp3TableState
    dispatch: React.Dispatch<AnyAction>
}

type OpenContextMenuCallbackType = (x: number, y: number, rowIndex: number, rowColumnIndex: number) => void

const ReportTableBlock = (props: ReportTableProps) => {
    const openContextMenuCallback = useCallback<OpenContextMenuCallbackType>((x, y, rowIndex, rowColumnIndex) => {
        props.dispatch(openTableContextMenu(x, y, rowIndex, rowColumnIndex, props.table.entityId))
    }, [props.table.entityId, props.dispatch]);

    return <WorkspaceBlock>
        <WorkspaceBlockHeader>
            <TableTitleContainer title={props.table.title} tableId={props.table.entityId}/>
            <Spacer/>
            { props.table.filtered && <Label text={"FILTERED"} variant={"disabled"}/> }
        </WorkspaceBlockHeader>
        <ReportTableWorkspaceBlockBody>
            { props.table.rows.map((row, rowIndex) =>
                <tr key={`dt_tr-${rowIndex}`}>
                    { row.cells
                        .map((cell, columnIndex) =>
                            <Rp3Cell
                                key={`rc-${columnIndex}`}
                                {...cell}
                                onContextMenu={openContextMenuCallback}
                            />
                        )
                    }
                </tr>
            )}
        </ReportTableWorkspaceBlockBody>
    </WorkspaceBlock>
}

type Rp3CellProps = Rp3CellState & {
    onContextMenu: OpenContextMenuCallbackType
}

const Rp3Cell = (cell: Rp3CellProps) => {
    return <td
        className={cell.className}
        colSpan={cell.colSpan}

        contextMenu={undefined}
        onContextMenu={(e) => {
            e.preventDefault()
            cell.onContextMenu(e.clientX - 2, e.clientY - 4, cell.rowIndex, cell.rowColumnIndex)
        }}
    >
        <Stack direction={"row"} alignItems={"center"} spacing={"4px"} flexWrap={"nowrap"}>
            <span>{cell.value}</span>
            { cell.refs !== null && cell.refs.map((ref, index) =>
                <Label
                    key={`ref-${index}`}
                    icon={<LinkIcon fontSize={"small"}/>}
                    text={ref}
                    variant={"info"}
                    size={"small"}
                />
            )}
        </Stack>
    </td>;
}

export {ReportTableBlock}
