import React, {useEffect, useReducer} from "react";
import {useMount, useTitle} from "react-use";

import {Main} from "../../../../../qdep/components/app/main/Main";
import {useReportPageAddress} from "../../base/useReportPageAddress";
import {Workspace} from "../../../../../qdep/components/app/workspace/Workspace";
import {ReportWorkspaceHeader} from "../../base/report-workspace-header/ReportWorkspaceHeader";
import {StageNames} from "../../base/StageNames";
import {setReportName, setReportStageStatus} from "../../base/report-workspace-header/data/ReportBaseActions";
import {ReportTablesContainer} from "../../base/report-tables-container/ReportTablesContainer";
import {withBaseReducer, withReportTablesContainerReducer} from "../../base/reportPageReducer";
import {CoverageTable} from "./table";
import {rp7CoverageReducer} from "./reducer";
import {defaultRp7CoverageState} from "./state";
import {CoverageTableMenu} from "./menu";
import {apiClient, ApiResponse} from "../../../../../app/client/app/client";
import {ReportRevisionDto} from "../../../../../app/client/app/entity/Report";
import {Rp7CoverageTable, Rp7PageCoverageMenuItem} from "../../../../../app/client/app/entity/Rp7Report";
import {startLoadingTables} from "../../base/report-tables-container/state";
import {setCoveragePageList, setCoveragePageTables} from "./action";


const pageReducer = withReportTablesContainerReducer(withBaseReducer(rp7CoverageReducer))

const CoverageTablePage = () => {
    const address = useReportPageAddress();
    const [state, dispatch] = useReducer(pageReducer, defaultRp7CoverageState);
    useTitle(state.reportName || "FSCT", {restoreOnUnmount: true})

    useMount(() => {
        apiClient
            .getReportRevision(address.prrKey)
            .then((response: ApiResponse<ReportRevisionDto>) => {
                if (response.data !== undefined) {
                    dispatch(setReportName(response.data.reportName))
                    dispatch(setReportStageStatus({
                        ocr: response.data.analysis.stages.ocr.status,
                        normalization: response.data.analysis.stages.normalization.status,
                        entityDetection: response.data.analysis.stages.entityDetection.status,
                        tableAnalysis: response.data.analysis.stages.tableAnalysis.status,
                        tableRefs: response.data.analysis.stages.tableRefs.status,
                        summary: response.data.analysis.stages.summary.status,
                    }))
                }
            })
    })

    useEffect(() => {
        apiClient
            .rp7ListCoverageMenuItems(address.prrKey)
            .then((response: ApiResponse<Rp7PageCoverageMenuItem[]>) => {
                if (response.data !== undefined) {
                    dispatch(setCoveragePageList(response.data))
                }
            })
    }, [address.prrKey])

    useEffect(() => {
        if (state.menu.selectedPage !== undefined) {
            dispatch(startLoadingTables())
            setTimeout(() => {
                if (state.menu.selectedPage !== undefined) {
                    apiClient
                        .rp7ListPageCoverageTables(address.prrKey, state.menu.selectedPage)
                        .then((response: ApiResponse<Rp7CoverageTable[]>) => {
                            if (response.data !== undefined) {
                                dispatch(setCoveragePageTables(response.data))
                            }
                        })
                }
            }, 200)
        }
    }, [address.prrKey, state.menu.selectedPage])

    return <Main>
        <CoverageTableMenu
            prrKey={address.prrKey}
            state={state.menu}
            dispatch={dispatch}
        />
        <Workspace>
            <ReportWorkspaceHeader
                prrKey={address.prrKey}
                reportName={state.reportName}
                reportStage={StageNames.SUMMARY}
                reportPage={state.menu.selectedPage}
                reportStageStatuses={state.reportStageStatuses}
                updateReportStatuses={stages => dispatch(setReportStageStatus(stages))}

                numCustomRuleUpdates={0}
                openCustomRuleBlock={() => {}}
            />

            <ReportTablesContainer isLoading={state.tableWorkspace.isLoading}>
                {state.tableWorkspace.tables.map(table =>
                    <CoverageTable key={table.entityId} table={table}/>
                )}
            </ReportTablesContainer>
        </Workspace>
    </Main>
}

export {CoverageTablePage}