import React, {useReducer} from "react";
import {useMount, useTitle} from "react-use";

import {Main} from "../../../../qdep/components/app/main/Main";
import {PageMenu} from "./menu";
import {useReportPageAddress} from "../base/useReportPageAddress";
import {Workspace} from "../../../../qdep/components/app/workspace/Workspace";
import {StageNames} from "../base/StageNames";
import {ReportWorkspaceHeader} from "../base/report-workspace-header/ReportWorkspaceHeader";
import {withBaseReducer, withReportTablesContainerReducer} from "../base/reportPageReducer";
import {defaultRp7State, Rp7MenuItemType} from "./data/state";
import {rp7Reducer} from "./data/reducer";
import {apiClient, ApiResponse} from "../../../../app/client/app/client";
import {ReportRevisionDto} from "../../../../app/client/app/entity/Report";
import {setReportName, setReportStageStatus} from "../base/report-workspace-header/data/ReportBaseActions";
import {CoverageOverview} from "./oveview/coverage/CoverageOverview";
import {IssueOverview} from "./oveview/issue/IssueOverview";
import {AutoLoadedIssueList} from "../base/issue-list/AutoLoadedIssueList";

import styles from './page.module.css'


const pageReducer = withReportTablesContainerReducer(withBaseReducer(rp7Reducer))

const ReportSummaryPage = () => {
    const address = useReportPageAddress();
    const [state, dispatch] = useReducer(pageReducer, defaultRp7State);
    useTitle(state.reportName || "FSCT", {restoreOnUnmount: true})

    useMount(() => {
        apiClient
            .getReportRevision(address.prrKey)
            .then((response: ApiResponse<ReportRevisionDto>) => {
                if (response.data !== undefined) {
                    dispatch(setReportName(response.data.reportName))
                    dispatch(setReportStageStatus({
                        ocr: response.data.analysis.stages.ocr.status,
                        normalization: response.data.analysis.stages.normalization.status,
                        entityDetection: response.data.analysis.stages.entityDetection.status,
                        tableAnalysis: response.data.analysis.stages.tableAnalysis.status,
                        tableRefs: response.data.analysis.stages.tableRefs.status,
                        summary: response.data.analysis.stages.summary.status,
                    }))
                }
            })
    })

    return <Main>
        <PageMenu
            prrKey={address.prrKey}
            state={state.menu}
            dispatch={dispatch}
        />
        <Workspace>
            <div className={styles.workspace_wrapper}>
                <ReportWorkspaceHeader
                    prrKey={address.prrKey}
                    reportName={state.reportName}
                    reportStage={StageNames.SUMMARY}
                    reportPage={state.menu.pageNum}
                    reportStageStatuses={state.reportStageStatuses}
                    updateReportStatuses={stages => dispatch(setReportStageStatus(stages))}

                    numCustomRuleUpdates={0}
                    openCustomRuleBlock={() => {}}
                />

                { state.menu.selectedItem === Rp7MenuItemType.OVERVIEW &&
                    <>
                        <IssueOverview/>
                        <CoverageOverview/>
                        {/*<OverviewSkeleton title={"Modification"}/>*/}
                    </>
                }

                { state.menu.selectedItem === Rp7MenuItemType.ISSUE_OVERVIEW &&
                    <AutoLoadedIssueList prrKey={address.prrKey}/>
                }
            </div>
        </Workspace>
    </Main>
}

export {ReportSummaryPage}