import React, {ReactElement, useMemo, useState} from "react";
import {Bar, BarChart, Cell, Pie, PieChart, ResponsiveContainer, Sector} from "recharts";

import {IconButton} from "@mui/material";
import BarChartIcon from '@mui/icons-material/BarChart';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';

import {Rp7CoverageHistogramBucket, Rp7OverviewCoverageData} from "../../../../../../app/client/app/entity/Rp7Report";

import styles from "./CoverageOverview.module.css";


interface ComponentProps {
    coverageHistogram: Rp7CoverageHistogramBucket[]
    selectBucket: (index: number) => void
}

interface OverviewCoverageDataState extends Rp7OverviewCoverageData {
    histogramView: HistogramBucketViewState[]
}

interface HistogramBucketViewState {
    index: number
    fill: string
    value: number
}

type ChartMode = "PIE" | "BAR"

const CoverageChart = (props: ComponentProps) => {
    const [chartMode, setChartMode] = useState<ChartMode>("PIE");
    const [activeBucket, setActiveBucket] = useState<number>(0);
    const chartData = useMemo<HistogramBucketViewState[]>(() => {
        let data = props.coverageHistogram
            .map((bucket, index) => {
                let color: string = 'rgb(255, 136, 0, 0.8)'
                if (index < 4) {
                    color = 'rgb(216,0,12, 0.8)'
                }
                if (index > 7) {
                    color = 'rgb(7, 163, 0, 0.8)'
                }
                return {
                    index: index,
                    fill: color,
                    value: bucket.tables !== null ? bucket.tables.length: 0,
                };
            });
        if (chartMode === "PIE") {
            data = data.filter(bucket => bucket.value > 0)
        }
        return data;
    }, [props.coverageHistogram, chartMode]);

    return <div className={styles.chart_block}>
        <div className={styles.chart_block_control_plane}>
            <IconButton
                color={chartMode === "PIE" ? "secondary": "default"}
                onClick={() => setChartMode("PIE")}
            >
                <DonutLargeIcon/>
            </IconButton>
            <IconButton
                color={chartMode === "BAR" ? "secondary": "default"}
                onClick={() => setChartMode("BAR")}
            >
                <BarChartIcon/>
            </IconButton>
        </div>
        <div className={styles.chart_block_content}>
                { chartMode === "PIE" &&
                    <ResponsiveContainer>
                        <PieChart width={350} height={350}>
                            <Pie
                                activeIndex={activeBucket}
                                activeShape={renderActiveShape}
                                inactiveShape={renderShape}
                                data={chartData}
                                innerRadius={"52%"}
                                outerRadius={"70%"}
                                dataKey="value"
                                cx="50%"
                                cy="50%"
                                onMouseEnter={(_, index) => setActiveBucket(index)}
                                onClick={(_, index) => props.selectBucket(chartData[index].index)}
                            />
                        </PieChart>
                    </ResponsiveContainer>
                }
                {chartMode === "BAR" &&
                    <ResponsiveContainer>
                        <BarChart width={350} height={350} data={chartData}>
                            <Bar dataKey="value" onClick={undefined}>
                                { chartData.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        cursor="pointer"
                                        fill={entry.fill}
                                        // todo selected #ff8800
                                    />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                }
        </div>
    </div>
}

const renderActiveShape = (props: any): ReactElement<SVGElement> => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text
                x={cx}
                y={cy}
                dy={8}
                textAnchor="middle"
                fontSize={"20px"}
                fill={fill}
            >
                <tspan x={cx} y={cy} dy={"-0.3em"}>{ payload.index * 10 } - { (payload.index + 1) * 10 } %</tspan>
                <tspan x={cx} y={cy} dy={"1.1em"}>Coverage Rate</tspan>
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle - 0.75}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value} Tables`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const renderShape = (props: any): ReactElement<SVGElement> => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle - 0.75}
                fill={fill}
            />
        </g>
    );
};

export {CoverageChart}
