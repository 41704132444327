import React, {ReactNode, useMemo} from "react";

import {Rp4EntityMatch, Rp4FeatureMatches, Rp4Table} from "../../../../../../app/client/app/entity/Rp4Report";
import {WorkspaceBlock, WorkspaceBlockHeader} from "../../../../../../qdep/components/app/workspace/Workspace";
import {TableTitleContainer} from "../../../base/report-table-title-container/TableTitleContainer";
import {ReportTableWorkspaceBlockBody} from "../../../base/report-table/ReportTableWorkspaceBlockBody";
import {ReportFeatures} from "../../../base/report-workspace-header/data/ReportFeatures";
import {extractCellTags} from "../../../base/util/CellTags";
import {PRRKey} from "../../../../../../app/client/app/entity/Project";

import commonTableStyles from "../../../base/report-table/ReportTableWorkspaceBlockBody.module.css";
import reportTableStyles from "../../../base/report-table/ReportTableWorkspaceBlockBody.module.css";


interface TableRow {
    cells: TableCell[]
    entityMatches: Rp4EntityMatch[] | null
    featureMatches: Rp4FeatureMatches[] | null
}

interface TableCell {
    column: number
    colSpan: number
    className: string | undefined
    value: React.ReactNode
}

interface ComponentProps {
    prrKey: PRRKey
    table: Rp4Table
}

const ReportTable = (props: ComponentProps) => {
    const data = useMemo<TableRow[]>(() => {
        return props.table.rows.map(row => ({
            cells: row.cells.map(cell => {
                const classes: string[] = []
                if (cell.cellType === "HEADER") {
                    classes.push(commonTableStyles.header)
                }
                if (cell.cellType === "DATA") {
                    classes.push(commonTableStyles.data_numeric)
                }

                let indent = 0
                if (cell.indent !== null && cell.indent > 0) {
                    indent = cell.indent
                }

                switch (indent) {
                    case 0: break
                    case 1:
                        classes.push(reportTableStyles.indent_1)
                        break
                    case 2:
                        classes.push(reportTableStyles.indent_2)
                        break
                    case 3:
                        classes.push(reportTableStyles.indent_3)
                        break
                    default:
                        classes.push(reportTableStyles.indent_4)
                }

                return {
                    column: cell.column,
                    colSpan: cell.colSpan,
                    className: classes.length === 0 ? undefined: classes.join(" "),
                    value: extractCellTags(cell, props.prrKey),
                };
            }),
            entityMatches: row.entityMatches,
            featureMatches: row.featureMatches,
        }))
    }, [props.table.rows, props.prrKey]);

    const reportFeatures: ReportFeatures = {
        rowDataGroup: false,
        rowDataGroupEntityNames: false,
        columnDataGroup: false,
        references: false,
        rowNumbers: true,
        columnNumbers: true,
        showGutterIcons: false,
        showCellValueTags: true,
    }

    return <WorkspaceBlock>
        <WorkspaceBlockHeader>
            <TableTitleContainer title={props.table.title} tableId={props.table.entityId}/>
        </WorkspaceBlockHeader>
        <ReportTableWorkspaceBlockBody>
            { renderColumnNums(reportFeatures, props.table.metadata.column.size) }
            { data.map((row, index) =>
                <tr key={`rr-${index}`}>
                    { renderRowNums(reportFeatures, index) }
                    { row.cells.map((cell: TableCell) =>
                        <td
                            key={cell.column}
                            colSpan={cell.colSpan}
                            className={cell.className}
                        >
                            { cell.value }
                        </td>
                    )}
                </tr>
            )}
        </ReportTableWorkspaceBlockBody>
    </WorkspaceBlock>
}

function renderRowNums(reportFeatures: ReportFeatures, rowIndex: number): React.ReactElement | undefined {
    if (reportFeatures.rowNumbers) {
        return <td className={commonTableStyles.row_number}>{rowIndex}</td>
    }
    return undefined;
}

function renderColumnNums(reportFeatures: ReportFeatures, size: number): React.ReactElement | undefined {
    if (reportFeatures.columnNumbers) {
        let cells: React.ReactElement[] = []

        if (reportFeatures.rowNumbers) {
            cells.push(<td key={"empty-row-num"} className={[commonTableStyles.column_number, commonTableStyles.row_number].join(" ")}/>)
        }
        if (reportFeatures.showGutterIcons) {
            cells.push(<td key={"empty-row-gutter"} className={[commonTableStyles.column_number, commonTableStyles.row_number].join(" ")}/>)
        }

        for (let index = 0; index < size; index++) {
            cells.push(<td key={`column-num-${index}`} className={commonTableStyles.column_number}>{index}</td>);
        }

        return <tr key={"column-nums-row"}>
            { cells }
        </tr>
    }
    return undefined;
}

export {ReportTable}