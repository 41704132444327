import React, {useState} from "react";
import {AnyAction} from "redux";

import {Divider, ListItemIcon, Menu, MenuItem} from "@mui/material";
import FormatSizeIcon from '@mui/icons-material/FormatSize'
import RemoveIcon from '@mui/icons-material/Remove'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'

import {EditCellSpanDialog} from "./EditCellSpanDialog";
import {EditCellValueDialog} from "./EditCellValueDialog";
import {closeTableContextMenu} from "../data/action";
import {Rp1ContextMenu} from "../data/reducer";
import {Rp1CustomRule, Rp1CustomRuleType} from "../../../../../app/client/app/entity/Rp1Report";
import {addCustomRule} from "../../base/report-custom-rule-pane/data/action";


interface TableContextMenuProps {
    contextMenu: Rp1ContextMenu
    dispatch: React.Dispatch<AnyAction>
}

const TableContextMenu = (props: TableContextMenuProps) => {
    const [openEditCellSpanDialog, setOpenEditCellSpanDialog] = useState(false);
    const [openEditCellValueDialog, setOpenEditCellValueDialog] = useState(false);

    return <>
        <Menu
            open={props.contextMenu.show}
            onClose={() => props.dispatch(closeTableContextMenu())}
            anchorReference="anchorPosition"
            anchorPosition={
                props.contextMenu.show
                    ? { top: props.contextMenu.position.y, left: props.contextMenu.position.x }
                    : undefined
            }
        >
            <MenuItem
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    setOpenEditCellSpanDialog(true)
                }}
            >
                <ListItemIcon>
                    <OpenInFullIcon/>
                </ListItemIcon>
                Edit Cell Span
            </MenuItem>
            <MenuItem
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    setOpenEditCellValueDialog(true)
                }}
            >
                <ListItemIcon>
                    <FormatSizeIcon/>
                </ListItemIcon>
                Edit Cell Value
            </MenuItem>
            <Divider/>
            <MenuItem
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    if (props.contextMenu.tableId && props.contextMenu.cellRef) {
                        const rule: Rp1CustomRule = {
                            type: Rp1CustomRuleType.DELETE_ROW,
                            tableId: props.contextMenu.tableId,
                            editCell: {
                                rowIndex: props.contextMenu.cellRef.row,
                                columnIndex: props.contextMenu.cellRef.columnIndex,
                                colSpan: undefined,
                                value: undefined,
                            },
                        }
                        props.dispatch(addCustomRule(rule, `Delete Row ${props.contextMenu.cellRef.row}`));
                    }
                }}
            >
                <ListItemIcon>
                    <RemoveIcon/>
                </ListItemIcon>
                Delete Row
            </MenuItem>
        </Menu>

        <EditCellSpanDialog
            isOpen={openEditCellSpanDialog}
            tableId={props.contextMenu.tableId}
            cellRef={props.contextMenu.cellRef}
            close={() => setOpenEditCellSpanDialog(false)}
            apply={(rule: Rp1CustomRule) => {
                setOpenEditCellSpanDialog(false)
                props.dispatch(addCustomRule(rule,
                    `Cell (${rule.editCell?.rowIndex}; ${rule.editCell?.columnIndex}) ColSpan: ${rule.editCell?.colSpan}`
                ))
            }}
        />
        <EditCellValueDialog
            isOpen={openEditCellValueDialog}
            tableId={props.contextMenu.tableId}
            cellRef={props.contextMenu.cellRef}
            close={() => setOpenEditCellValueDialog(false)}
            apply={(rule: Rp1CustomRule) => {
                setOpenEditCellValueDialog(false)
                props.dispatch(addCustomRule(rule,
                    `Cell (${rule.editCell?.rowIndex}; ${rule.editCell?.columnIndex}) New Value: "${rule.editCell?.value}"`
                ))
            }}
        />
    </>
}

export {TableContextMenu}
